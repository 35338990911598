import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom"
import Home from "./Home.js"
import Services from "./Services.js"
import Portfolio from "./Portfolio.js"
import Contact from "./Contact.js"
import "./App.css"

function App() {
  return (
    <Router>
      <div className="container-fluid">
        <div className="row sticky-top">
          <div className="d-none d-md-block d-lg-block d-xl-block col-2 col-md-2 col-lg-2 col-xl-2 logo">
            <img alt="Infinite Loop" src="assets/images/logo.png" />
          </div>
          <div className="d-md-none d-lg-none d-xl-none d-sm-block col-3 col-sm-3 logo">
            <img alt="Infinite Loop" src="assets/images/logo-sm.png" />
          </div>
          <div className="col-9 col-sm-9 col-md-10 col-lg-10 col-xl-10">
            <div className={ "row masthead gradient" + (Math.floor(Math.random() * 5) + 1) }>
              <div className="col-md-11 col-lg-11 col-xl-11">
                <div className="container-fluid">
                  <div className="row">
                    <div className="d-none d-md-block d-lg-block d-xl-block col-md-6 col-lg-6 col-xl-6" />
                    <div className="col-11 col-sm-11 col-md-6 col-lg-6 col-xl-6 text-center">
                      <div className="container-fluid">
                        <div className="row">
                          <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                            <Link to="/">
                              <i className="d-block d-sm-block d-md-block d-lg-none d-xl-none fa fa-home fa-4x" aria-hidden="true" />
                              <span className="d-none d-lg-block d-xl-block">home</span>
                            </Link>
                          </div>
                          <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                            <Link to="/services">
                              <i className="d-block d-sm-block d-md-block d-lg-none d-xl-none fas fa-info-circle fa-4x" aria-hidden="true" />
                              <span className="d-none d-lg-block d-xl-block">services</span>
                            </Link>
                          </div>
                          <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                            <Link to="/portfolio">
                              <i className="d-block d-sm-block d-md-block d-lg-none d-xl-none fas fa-certificate fa-4x" aria-hidden="true" />
                              <span className="d-none d-lg-block d-xl-block">portfolio</span>
                            </Link>
                          </div>
                          <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                            <Link to="/contact">
                              <i className="d-block d-sm-block d-md-block d-lg-none d-xl-none fa fa-address-card fa-4x" aria-hidden="true" />
                              <span className="d-none d-lg-block d-xl-block">contact</span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Routes>
          <Route path="/services" element={<Services />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </div>
    </Router>
  )
}

export default App
