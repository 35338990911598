import React from "react"

const Contact = () =>
    <div className="row">
        <div className="d-none d-md-block d-lg-block d-xl-block col-md-1 col-lg-1 col-xl-1 " />
        <div className="col-12 col-sm-12 col-md-11 col-lg-11 col-xl-11">
            <h1 className="display-4">Contact</h1>
            <p className="text-muted">Please contact us using one of the methods below.</p>
            <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="list-group">
                        <a className="list-group-item list-group-item-action" href="https://www.linkedin.com/company/infinite-loop-limited/">LinkedIn</a>
                        <a className="list-group-item list-group-item-action" href="mailto:info@infiniteloop.co.nz">Email</a>
                        <a className="list-group-item list-group-item-action" href="tel:+64210520379">Phone +64 21 052 0379</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

export default Contact