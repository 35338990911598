import React from "react"

const style = {
    marginBottom: 0
}

const Portfolio = () =>
    <div className="row">
        <div className="d-none d-md-block d-lg-block d-xl-block col-md-1 col-lg-1 col-xl-1 " />
        <div className="col-12 col-sm-12 col-md-11 col-lg-11 col-xl-11">
            <h1 className="display-4">Portfolio</h1>
            <p className="lead">Licenses &amp; Certifications</p>
            <hr />
            <p className="text-muted">Badges</p>
            <span>
                <a href="https://www.credly.com/badges/de693d1d-a836-491b-951a-aedde3301d9d">
                    <img alt="Azure" src="assets/images/azure.png" />
                </a>
                { /*<a href="https://www.credential.net/29nebr4f?key=24e4db313f6ba89f7bd472dd17d8abd9ea2b6d4ccea31a88824469b8a6d60011">
                    <img alt="GCP" src="assets/images/gcp.png" />
                </a>*/ }
                <a href="https://google.accredible.com/38c35cc2-d11c-4357-83cf-38fc525165b0?key=7e1a739792ad4d1b7b97d42d409e7cfff053c201034cc4dfab0f62de406f64f5">
                    <img alt="GCP" src="assets/images/gcp.png" />
                </a>
                <a href="https://www.credly.com/badges/5562f8c4-9c60-409c-9f83-2a054831b04c">
                    <img alt="AWS" src="assets/images/aws.png" />
                </a>
                <a href="https://www.credly.com/badges/62accf1f-8fbc-4a62-b147-f844aa9bac84">
                    <img alt="CKA" src="assets/images/cka.png" />
                </a>
            </span>
            <hr />
            <p className="text-muted">Other</p>
            <div className="row">
                <div className="col-12 col-sm-12 col-md-10 col-lg-6 col-xl-6">
                    <div className="list-group">
                        <p className="list-group-item list-group-item-action list-group-item-secondary" style={ style }>2020</p>
                        <a className="list-group-item list-group-item-action" href="https://www.coursera.org/account/accomplishments/specialization/6N3Q6CLWDQAJ">AWS Fundamentals Specialization</a>
                        <p className="list-group-item list-group-item-action list-group-item-secondary" style={ style }>2019</p>
                        <a className="list-group-item list-group-item-action" href="https://www.coursera.org/account/accomplishments/specialization/HFRM6PGP2G2P">Architecting with Google Kubernetes Engine Specialization</a>
                        <a className="list-group-item list-group-item-action" href="https://www.youracclaim.com/badges/e6259dce-cfe1-44fc-a60f-6b99b7864d47">AZ-301 Microsoft Azure Architect Design</a>
                        <a className="list-group-item list-group-item-action" href="https://www.youracclaim.com/badges/4d1e09d7-6bb4-490d-8cd0-bfcaf4dbbd4d">AZ-300 Microsoft Azure Architect Technologies</a>
                        <a className="list-group-item list-group-item-action" href="https://www.youracclaim.com/badges/1d082498-dbb4-4930-ac56-896e919c55f8">Kubernetes Fundamentals (LFS258)</a>
                        <p className="list-group-item list-group-item-action list-group-item-secondary" style={ style }>2018</p>
                        <a className="list-group-item list-group-item-action" href="https://www.coursera.org/account/accomplishments/specialization/X3W936XSYCVV">Architecting with Google Cloud Platform Specialization</a>
                        <a className="list-group-item list-group-item-action" href="https://www.coursera.org/account/accomplishments/specialization/L7Z8GVDYVAP6">Developing Applications with Google Cloud Platform Specialization</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

export default Portfolio