import React from "react"
import { Link } from "react-router-dom"

const blurb = "Cloud agnostic technology specialists and consulting services"

const Home = () =>
    <div className="row">
        <div className="d-none d-md-block d-lg-block d-xl-block col-md-1 col-lg-1 col-xl-1" />
        <div className="col-12 col-sm-12 col-md-11 col-lg-11 col-xl-11">
            <br />
            <h1 className="d-none d-md-block d-lg-block d-xl-block display-2">
                <img alt="Infinite Loop" src="assets/images/header.png" />
            </h1>
            <h1 className="d-block d-md-small d-md-none d-lg-none d-xl-none display-5">
                <img alt="Infinite Loop" src="assets/images/header-sm.png" />
            </h1>
            <h1 className="d-none d-md-block d-lg-block d-xl-block display-4">{ blurb }</h1>
            <h1 className="d-block d-md-small d-md-none d-lg-none d-xl-none display-5">{ blurb }</h1>
            <p className="lead">Welcome, thanks for stopping by. We are passionate technologists and we absolutely ❤️ ☁️!</p>
            <p className="text-muted">We have 20+ years experience in designing, developing, and delivering software products. We have a broad range of industry experience including health, utilities, transport, and finance, as well as working with businesses ranging from tiny startups, to huge multi-national organisations, including government. We are certified on the "Big 3" public cloud providers. Please visit the { <Link className="list-group-item-action" to="/services">services</Link>} page to see the types of services we can provide to your business.</p>
        </div>
    </div>

export default Home