import React from "react"
import { Link } from "react-router-dom"

const Services = () =>
    <div className="row">
        <div className="d-none d-md-block d-lg-block d-xl-block col-md-1 col-lg-1 col-xl-1 " />
        <div className="col-12 col-sm-12 col-md-11 col-lg-11 col-xl-11">
            <h1 className="display-4">Services</h1>
            <p className="text-muted">We are cloud agnostic. Below is a non-exhaustive list of the types of services we can provide your business and technologies that we have experience with. { <Link className="list-group-item-action" to="/contact">Contact</Link> } us today to find out how we can help.</p>
            <p className="lead">Services</p>
            <ul>
                <li>Cloud Architecture</li>
                <li>Solutions Architecture</li>
                <li>Applications Architecture</li>
                <li>Technical Lead/Team Lead</li>
                <li>Cloud Infrastructure</li>
                <li>SRE and DevOps</li>
                <li>Data Science</li>
                <li>Cloud Native Development</li>
                <li>Full Stack Development</li>
                <li>Mobile Development</li>
            </ul>
            <p className="lead">Technologies</p>
            <ul>
                <li>Cloud - Kubernetes, Docker, PaaS, Serverless</li>
                <li>Languages - Java, JavaScript, Objective-C, C#, Python, Groovy, Clojure, TypeScript, PL/SQL</li>
                <li>Frontend - React, AngularJS, jQuery</li>
                <li>Backend - Spring, SpringBoot, NodeJS, Express</li>
                <li>Mobile - React Native, Cordova (PhoneGap)</li>
                <li>Database - PostgreSQL, Oracle, MySQL, MongoDB, SQL Server</li>
                <li>Data Science - Pandas, scikit-learn, Matplotlib, TensorFlow</li>
                <li>CI/CD - Azure DevOps, Jenkins, Bamboo</li>
                <li>SCM - Git, GitHub, GitLab, Bitbucket, SVN</li>
                <li>IaC - Terraform, ARM templates, Cloud Formation</li>
                <li>Observability - Elastic Cloud, Beats, Rancher</li>
                <li>Other - Jira, Confluence, OpsGenie, Stackdriver, Forseti, Akamai</li>
            </ul>
        </div>
    </div>

export default Services